<template>
    <v-row no-gutters>
        <v-col :class="($vuetify.breakpoint.smAndDown ? 'col-12' : 'pr-4 col-60-pct')">
            <v-card elevation="6" class="mx-auto chart-container">
                <v-overlay
                        :opacity="0.15"
                        :value="loader"
                        absolute
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="40"
                                    width="6"
                            />
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-card-text>
                    <div class="black--text darken-1 label">{{ label1 }}</div>
                    <div class="black--text darken-1" style="height: 22px !important;"></div>
                    <div class="error-container" v-if="loadingError">
                        <v-alert dense outlined type="error" class="mt-3 mb-0">{{ errorMessage }}</v-alert>
                    </div>
                    <ECharts
                        :options="getChartOptions()"
                        :style="cssVars"
                        autoresize
                        @click="params => openBarDetails(params)"
                        @rendered="rendered()"
                    />
                </v-card-text>
            </v-card>
        </v-col>
        <v-col :class="($vuetify.breakpoint.smAndDown ? 'mt-5 col-12' : 'pl-2 col-40-pct')">
            <v-card elevation="6" class="mx-auto card-container" :height="$vuetify.breakpoint.smAndDown ? '' : '495px'">
                <v-card-text>
                    <v-overlay
                            :opacity="0.15"
                            :value="loader"
                            absolute
                    >
                        <v-row>
                            <v-col class="text-center">
                                <v-progress-circular
                                        color="primary"
                                        indeterminate
                                        size="40"
                                        width="6"
                                />
                            </v-col>
                        </v-row>
                    </v-overlay>
                    <div class="d-flex flex-row black--text text--darken-1 label">{{ payoutLabel1 }}</div>
                    <div class="black--text darken-1">{{ payoutLabel2 }}</div>
                    <div class="error-container" v-if="loadingError">
                        <v-alert dense outlined type="error" class="mt-3 mb-0">{{ errorMessage }}</v-alert>
                    </div>
                    <div class="mt-4"></div>
                    <template v-for="monthlyPayout in monthlyPayouts">
                        <v-row :key="monthlyPayout['label']">
                            <v-col class="label-normal black--text text--darken-1 col-sm-7 col-6">{{ monthlyPayout['label'] }}</v-col>
                            <v-col class="text-right label-normal blue--text text--darken-1 d-flex flex-row align-end justify-end">{{ '$' + formatThisNumber(monthlyPayout['amount'], '0,0') }}</v-col>
                        </v-row>
                    </template>
                    <v-row>
                        <v-col class="label-normal black--text text--darken-1 col-sm-7 col-6">{{ payoutLabel4 }}</v-col>
                        <v-col class="text-right label-normal blue--text text--darken-1 d-flex flex-row align-end justify-end">{{ '$' + formatThisNumber(payoutAmount3, '0,0') }}</v-col>
                    </v-row>
                    <v-row>
                        <v-col class="label-normal black--text text--darken-1 col-sm-7 col-6">{{ payoutLabel5 }}</v-col>
                        <v-col class="text-right label-normal blue--text text--darken-1 d-flex flex-row align-end justify-end">{{ '$' + formatThisNumber(payoutAmount4, '0,0') }}</v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import "echarts/lib/chart/bar";
import "echarts/lib/chart/line";
import 'echarts/lib/component/markLine';
import 'echarts/lib/component/markPoint';
import "echarts/lib/component/legend";
import "echarts/lib/component/title";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/axisPointer";

import ECharts from "vue-echarts";
import {api} from "Api";
import {log, numberFormat} from "Helpers/helpers";

export default {
    name: "CommissionForecastChartAndPayOut",
    props: ['conditions'],
    components: {ECharts},
    data() {
        return {
            chartHeight: '420px',
            errorMessage: null,
            excellenceCommissionTarget: 0,
            expectedCommission: [],
            label1: '',
            label2: '',
            loader: true,
            loadingError: false,
            monthlyPayouts: [],
            paidGrossMargin: [],
            payoutLabel1: '',
            payoutLabel2: '',
            payoutLabel3: '',
            payoutLabel4: '',
            payoutLabel5: '',
            payoutAmount1: 0,
            payoutAmount2: 0,
            payoutAmount3: 0,
            payoutAmount4: 0,
            projectedGrossMargin: [],
            unpaidGrossMargin: [],
            xAxisData: [],
            yAxisLabel1: '',
            yAxisLabel2: '',
            yAxisLabel3: '',
            yAxisInterval1: 0,
            yAxisInterval2: 0,
            yAxisInterval3: 0,
            yAxisMax1: 0,
            yAxisMax2: 0,
            yAxisMax3: 0
        }
    },
    computed: {
        cssVars() {
            let width = '100%'
            let height = this.chartHeight
            return {
                '--chart-width': width,
                '--chart-height': height,
            }
        },
    },
    methods: {
        formatThisNumber(value, format) {
            return numberFormat(value, format)
        },
        getChartOptions () {
            let series = [
                {
                    type: 'bar',
                    name: 'Paid Inv',
                    yAxisIndex: 0,
                    data: this.paidGrossMargin,
                    stack: 'grossMargin',
                    itemStyle: {
                        color: 'green'
                    }
                },
                {
                    type: 'bar',
                    name: 'Unpaid Inv',
                    yAxisIndex: 0,
                    data: this.unpaidGrossMargin,
                    stack: 'grossMargin',
                    itemStyle: {
                        color: 'red'
                    }
                },
                {
                    type: 'bar',
                    name: 'Forecasted Inv',
                    yAxisIndex: 0,
                    data: this.projectedGrossMargin,
                    stack: 'grossMargin',
                    itemStyle: {
                        color: 'orange'
                    }
                },
                {
                    type: 'bar',
                    yAxisIndex: 2,
                    data: this.expectedCommission,
                    itemStyle: {
                        color: '#FFFFFF'
                    }
                },
                {
                    type: 'bar',
                    yAxisIndex: 2,
                    data: [0, 0, 0, this.excellenceCommissionTarget],
                    label: {
                        show: true,
                        formatter: (params) => {
                            return params.value > 0 ? '5%' : ''
                        },
                        position: 'top',
                        fontFamily: 'Roboto, sans-serif',
                        fontSize: 16,
                        color: 'black',
                    },
                    itemStyle: {
                        color: '#11B1CC'
                    }
                }
            ]
            return {
                tooltip: {
                    show: true,
                    axisPointer: {
                        snap: true
                    },
                    trigger: 'item',
                    type: 'cross',
                    formatter: '{b0}: ${c0}'
                },
                legend: {
                    data: ['Paid Inv','Unpaid Inv','Forecasted Inv'],
                    top: 0,
                    left: 65,
                    textStyle: {
                        fontFamily: 'Roboto, sans-serif',
                        fontSize: 14,
                        color: 'black',
                    },
                    orient: 'vertical'
                },
                xAxis: [
                    {
                        data: this.xAxisData,
                        type: 'category',
                        axisLine: {
                            lineStyle: {
                                color: 'black'
                            }
                        },
                        axisLabel: {
                            fontFamily: 'Roboto, sans-serif',
                            fontSize: 14,
                            color: 'black'
                        },
                        axisPointer: {
                            show: false,
                            snap: false
                        },
                        name: 'Current Q',
                        nameTextStyle: {
                            padding: [20, 5],
                            fontFamily: 'Roboto, sans-serif',
                            fontSize: 16,
                            color: 'black'
                        },
                        nameLocation: 'center',
                        position: 'bottom',
                    }
                ],
                yAxis: [
                    {
                        name: this.yAxisLabel1,
                        nameTextStyle: {
                            fontFamily: 'Roboto, sans-serif',
                            fontSize: 16,
                            color: 'black',
                            align: 'right'
                        },
                        type: 'value',
                        splitLine: {
                            show: false
                        },
                        min: 0,
                        interval: this.yAxisInterval1,
                        max: this.yAxisMax1,
                        axisLabel: {
                            formatter: '${value}',
                            fontFamily: 'Roboto, sans-serif',
                            fontSize: 14,
                            color: 'black'
                        },
                        axisPointer: {
                            show: true,
                            snap: true,
                            lineStyle: {
                                type: 'dashed'
                            }
                        },
                        show: false,
                        position: 'left',
                    },
                    {
                        position: 'left',
                        name: this.yAxisLabel3,
                        nameTextStyle: {
                            fontFamily: 'Roboto, sans-serif',
                            fontSize: 16,
                            color: 'black',
                            align: 'right'
                        },
                        type: 'value',
                        splitLine: {
                            show: false
                        },
                        min: 0,
                        interval: this.yAxisInterval3,
                        max: this.yAxisMax3,
                        axisLabel: {
                            // formatter: '${value}K',
                            formatter: (value)=>{
                                return '$' + Math.round(value).toString() + 'K'
                            },
                            fontFamily: 'Roboto, sans-serif',
                            fontSize: 14,
                            color: 'black'
                        },
                        axisPointer: {
                            show: false,
                            snap: false,
                            lineStyle: {
                                type: 'dashed'
                            }
                        },
                    },
                    {
                        name: this.yAxisLabel2,
                        nameTextStyle: {
                            fontFamily: 'Roboto, sans-serif',
                            fontSize: 16,
                            color: 'black',
                            align: 'left'
                        },
                        type: 'value',
                        min: 0,
                        interval: this.yAxisInterval2,
                        max: this.yAxisMax2,
                        splitLine: {
                            show: false
                        },
                        axisLabel: {
                            formatter: '${value}',
                            fontFamily: 'Roboto, sans-serif',
                            fontSize: 14,
                            color: 'black'
                        },
                        axisPointer: {
                            show: true,
                            snap: true,
                            lineStyle: {
                                type: 'dashed'
                            }
                        },
                        position: 'right',
                    }
                ],
                series: series,
                grid: {
                    show: false,
                    left: 50,
                    right: 50,
                    top: 30
                }
            }
        },
        getValues() {
            return new Promise((resolve, reject) => {
                const url = '/sparkline/chart/gm-commission-forecast'
                if(url) {
                    api
                        .get(url, {
                            params: {
                                conditions: this.conditions ? this.conditions : []
                            }
                        })
                        .then((response) => {
                            const data = response.data.data
                            resolve(data)
                        })
                        .catch((error) => {
                            reject(error)
                        })
                } else {
                    reject('error')
                }
            })
        },
        handleResize() {
            // if(window.innerHeight - (375) > 400) this.chartHeight = window.innerHeight - (375) + 'px';
        },
        openBarDetails (params) {
        },
        rendered (){
            // this.loader = false;
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize)
    },
    mounted() {
        this.getValues()
            .then((data) => {
                this.excellenceCommissionTarget = data.excellenceCommissionTarget
                this.label1 = data.label1
                this.label2 = data.label2
                this.yAxisLabel1 = data.yAxisLabel1
                this.yAxisLabel2 = data.yAxisLabel2
                this.yAxisLabel3 = data.yAxisLabel3
                this.xAxisData = data.xAxisData
                this.expectedCommission = data.expectedCommission
                this.paidGrossMargin = data.paidGrossMargin
                this.projectedGrossMargin = data.projectedGrossMargin
                this.unpaidGrossMargin = data.unpaidGrossMargin
                this.yAxisMax1 = data.yAxisMax1
                this.yAxisInterval1 = data.yAxisInterval1
                this.yAxisMax2 = data.yAxisMax2
                this.yAxisInterval2 = data.yAxisInterval2
                this.yAxisMax3 = data.yAxisMax3
                this.yAxisInterval3 = data.yAxisInterval3
                this.monthlyPayouts = data.monthlyPayouts
                this.payoutLabel1 = data.payoutLabel1
                this.payoutLabel2 = data.payoutLabel2
                this.payoutLabel3 = data.payoutLabel3
                this.payoutLabel4 = data.payoutLabel4
                this.payoutLabel5 = data.payoutLabel5
                this.payoutAmount1 = data.payoutAmount1
                this.payoutAmount2 = data.payoutAmount2
                this.payoutAmount3 = data.payoutAmount3
                this.payoutAmount4 = data.payoutAmount4

                this.loader = false
                this.handleResize()
            })
            .catch((error) => {
                this.loader = false
                this.loadingError = true
                this.errorMessage = error
            })
    },
    beforeUpdate() {
        this.handleResize()
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style scoped>
.col-40-pct {
    max-width: 40%;
}
.col-60-pct {
    max-width: 60%;
}
.chart-container {
    position: relative;
    /*width: 60% !important;*/
}
.card-container {
    position: relative;
    /*width: 40% !important;*/
}
.label {
    font-weight: bold !important;
    font-size: 1.25em !important;
}
.label-normal {
    font-size: 16px !important;
}
</style>